import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HaderHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="relative md:pt-[250px] pt-[200px] pb-[20px] md:pb-[120px] w-full mx-auto flex md:flex-row flex-col p-6">
      <div className="absolute z-10 w-full h-full top-0 left-0">
        <video

          playsInline
          autoPlay
          muted
          loop
          className="w-full h-full md:h-full object-cover"
        >
          <source
            src={"https://firebasestorage.googleapis.com/v0/b/videos2-986b9.appspot.com/o/madison%2C%20wi%20(2).mp4?alt=media&token=c9480f5a-5338-47a8-b9b2-b9c64c3b90a0"}
            type="video/mp4"
          />
        </video>
      </div>
      <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/60"></div>
      <div className="relative z-50 flex md:flex-row flex-col p-6">
        <div className="md:w-1/2 w-full md:text-start text-center">
          <h1 className=" md:text-start text-center text-white">
            {rpdata?.dbSlogan?.[1].slogan}
          </h1>
          <p className="md:text-start text-center text-white">
            {rpdata?.dbValues?.[0].description}
          </p>
          <ButtonContent btnStyle="three" />
        </div>

      </div>
    </div>
  );
}

export default HaderHome;
